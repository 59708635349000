import type {
  PropertyFilterOperator,
  PropertyFilterProperty,
} from '@cloudscape-design/collection-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BadgeList from '@/components/BadgeList';
import { useGetUserGroupsQuery, useGetUsersQuery } from '@/generated/graphql';
import { toSingleCell } from '@/utils/table/utils/cellUtils';

import type { FieldConfig } from '../types';

const createOwnerFieldPropertyFilter = (
  options: { label: string; id: string }[]
): Partial<PropertyFilterProperty> => ({
  operators: (['=', ':'] as PropertyFilterOperator[]).map((operator) => ({
    operator,
    format: (id): string => {
      return options.find((t) => t.id === id)?.label || '-';
    },
    match: (users: unknown, id: string) => {
      return !!((users || []) as { label: string; id: string }[]).find(
        (t) => t.id === id
      );
    },
  })),
});

export function useGetOwnersFieldConfig<
  T extends {
    Id: string;
    allOwners: { label: string; id: string }[];
  },
>(): FieldConfig<T> {
  const { data: users } = useGetUsersQuery();
  const { data: userGroups } = useGetUserGroupsQuery();
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const userOptions = useMemo(
    () =>
      users?.user.map((u) => ({
        label: u.FriendlyName ?? '',
        id: u.Id ?? '',
      })) ?? [],
    [users?.user]
  );
  const groupOptions = useMemo(
    () =>
      userGroups?.user_group.map((u) => ({ label: u.Name, id: u.Id })) ?? [],
    [userGroups?.user_group]
  );

  return useMemo(
    () => ({
      header: t('owners'),
      cell: (item) => (
        <BadgeList badges={item.allOwners.map((owner) => owner.label)} />
      ),
      filterOptions: {
        filteringProperties: createOwnerFieldPropertyFilter([
          ...userOptions,
          ...groupOptions,
        ]),
        filteringOptions: userOptions.map((u) => ({
          value: u.id,
          label: u.label,
        })),
      },
      sortingComparator: (a, b) => {
        const ownersA = toSingleCell(a.allOwners);
        const ownersB = toSingleCell(b.allOwners);

        return ownersA.localeCompare(ownersB);
      },
      exportVal: (item) =>
        item.allOwners.map((owner) => owner.label || '').join(','),
    }),
    [groupOptions, t, userOptions]
  );
}
